.datenschutz-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    text-align: left;
  }
  
  .datenschutz-container h1,
  .datenschutz-container h2,
  .datenschutz-container h3 {
    color: #333;
  }
  
  .datenschutz-container h1 {
    font-size: 2.5em;
    margin-bottom: 0.5em;
  }
  
  .datenschutz-container h2 {
    font-size: 2em;
    margin-top: 1em;
    margin-bottom: 0.5em;
  }
  
  .datenschutz-container h3 {
    font-size: 1.5em;
    margin-top: 1em;
    margin-bottom: 0.5em;
  }
  
  .datenschutz-container p {
    margin-bottom: 1em;
  }
  
  .datenschutz-container a {
    color: #0066cc;
    text-decoration: none;
  }
  
  .datenschutz-container a:hover {
    text-decoration: underline;
  }
  
.navbar-container { /*Ganzer Content auf Webseite*/
  width: 100%;
  text-align: center;
  background-color: #ffffff;
  top: 0;
  z-index: 1000; /*TODO MAYBE*/
}

.banner {
  display: grid;
  grid-template-columns: repeat(18, 1fr);
  grid-auto-rows: 40px;
  gap: 1px;
  background-color: #ffffff;
}

.logo {
  grid-column: 2 / 7;
  grid-row: 1 / 8;
  align-content: end;
}

.logo img{
  max-height: 100%;
  max-width: 100%;
}

.banner-text{
  grid-row: span 1;
  grid-column: 8 / 19;
  font-family: "Roboto";
  font-weight: 600;
  margin: 5px;
  align-content: center;
  text-align: left;
  margin-left: 0;
  font-size: 13pt;
}

.color-fade-1, 
.color-fade-2, 
.color-fade-3, 
.color-fade-4, 
.color-fade-5 {
  max-width: 100%;
  max-height: 100%;
  grid-row: 7 / 7;
}

.color-fade-1{
  grid-column: 8 / 10;
  z-index: 9;
  background: linear-gradient(to bottom, rgba(55, 124, 254, 1) 50%, white 100%);
}
.color-fade-2{
  grid-column: 10 / 12;
  z-index: 9;
  background: linear-gradient(to bottom, rgba(126, 218, 85, 1) 50%, white 100%);
}
.color-fade-3{
  grid-column: 12 / 14;
  z-index: 9;
  background: linear-gradient(to bottom, rgba(254, 50, 49, 1) 50%, white 100%);
}
.color-fade-4{
  grid-column: 14 / 16;
  z-index: 9;
  background: linear-gradient(to bottom, rgba(252, 188, 88, 1) 50%, white 100%);
}
.color-fade-5{
  grid-column: 16 / 18;
  z-index: 9;
  background: linear-gradient(to bottom, rgba(170, 170, 170, 1) 50%, white 100%);
}

.banner-image-container {
  display: flex;
  align-items: flex-end; /* Bilder am unteren Rand ausrichten */
  width: 100%;
  height: 100%;
}

.banner-image-1,
.banner-image-2,
.banner-image-3,
.banner-image-4,
.banner-image-5 {
  width: 100%;
  max-height: 100%;
  z-index: 10;
  grid-row: 2 / 7;
  object-fit: cover;
}

.banner-image-1 {
  grid-column: 8 / 10;
}

.banner-image-2 {
  grid-column: 10 / 12;
}

.banner-image-3 {
  grid-column: 12 / 14;
}

.banner-image-4 {
  grid-column: 14 / 16;
}

.banner-image-5 {
  grid-column: 16 / 18;
}

.navbar {
  background-color: #545454;
  padding: 1rem;
}

.hamburger {
  display: none;
  cursor: pointer;
  font-size: 2rem;
}

.nav-links {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
  font-weight: 400;
  transition: max-height 0.3s ease-out;
  margin: 0;
  align-items: center;
}

.nav-link{
  font-weight: 400;
  font-size: larger;
  align-items: center;
  font-family: "Roboto";
}

.nav-link:hover{
  color: lightgray;
}

.nav-links li {
  margin: 0 1rem;
  position: relative;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-family: "Roboto";
}

.nav-links a:hover {
  color: lightgray;
}

.dropdown span {
  cursor: pointer;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #545454;
  z-index: 1;
}

.dropdown span{
  color: white;
  font-weight: 400;
}

.nav-links, .dropdown-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu li {
  margin: 0;
}

.dropdown-menu li a {
  padding: 10px 20px;
  display: block;
  white-space: nowrap;
  color: white;
  font-weight: 400;
  text-decoration: none;
  text-align: left;
}

/* .dropdown-menu li a:hover {
  background-color: #f1f1f1;
} */

/* Media Query für Mobilgeräte */
@media (max-width: 768px) {
  .navbar-container {
    overflow: hidden;
  }

  .banner {
    justify-content: space-between;
    padding: 0 1rem;
  }

  .banner-text {
    display: none;
  }

  .banner-images img {
    display: none;
  }

  .logo img {
    height: 80px; /* Optional: Größe des Logos auf Mobilgeräten anpassen */
    margin-right: 0; /* Entfernen des rechten Abstands auf Mobilgeräten */
  }

  .navbar {
    padding: 0.5rem; /* Optional: Padding der Navigation auf Mobilgeräten anpassen */
  }

  .nav-links {
    flex-direction: column;
    max-height: 0;
    overflow: hidden;
  }

  .nav-links.open {
    max-height: 300px; /* Adjust based on the number of links */
  }

  .nav-links li {
    margin: 0.5rem 0;
  }

  .hamburger {
    display: block;
  }

  .dropdown:hover .dropdown-menu {
    display: none; /* Disable hover on mobile */
  }

  .dropdown.open .dropdown-menu {
    display: block;
  }
}

.contact-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.contact-column-1 {
  display: flex;
  flex-direction: column;
  width: 400px;
  justify-content: center;
}

.contact-column-2 {
  display: flex;
  flex-direction: column;
  width: 400px;
}

.contact-row {
  left: 80%;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.contact-row p {
  align-content: center;
  text-align: left;
}

.gm-style-cc {
  display: none;
}

.gmnoprint {
  display: none;
}

.map-container {
  height: 300px;
  width: 400px;
}

.info-window {
  height: 70px;
}

.icon-border {
  height: 50px;
  width: 50px;
}

.contact-icons {
  height: 50px;
  width: 50px;
  margin: 10px;
  align-self: center;
}

.unternehmen-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Zentriert den Inhalt vertikal */
}

.unternehmen-content-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
  /* font-size: 20pt; */
}

.left-content div {
  border-radius: 0px 15px 15px 15px;
}

.right-content div {
  border-radius: 15px 0px 15px 15px;
  margin-left: auto
}

.text-container,
.title-container {
  padding: 15px;
  margin: 10px;
  text-align: left;
  max-width: 80%;
}

.title-container {
  font-weight: 600;
  color: white;
  background-color: gray;
  margin-top: 50px;
  font-size: 22pt;
}

.title-container .right-content {
  justify-content: right;
}

.text-container {
  background-color: lightgray;
  font-size: 20pt;
}

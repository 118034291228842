.footer-container {
  display: flex;
  justify-content: space-around;
  background-color: #545454;
  color: #fff;
  padding: 20px;
  margin-top: 50px;
}

.footer-section {
  flex: 1;
  margin: 0 50px 50px;
  text-align: left;
}

.footer-section h3 {
  border-bottom: 1px solid #fff;
  padding-bottom: 10px;
}

.footer-section p {
  margin: 10px 0;
}

.footer-section a {
  color: #fff;
  text-decoration: none;
}

.footer-section a:hover {
  text-decoration: underline;
}

.social-media {
  margin-top: 10px;
}

.social-media a {
  display: inline-block;
  margin-right: 10px;
}

.team-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
}

.person{
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.person-image, .person-text{
    max-width: 300px;
}

.person-image{
    margin: 0;
    justify-content: center;
    width: auto;
    max-height: 350px;
    object-fit: cover;
}

.person-text{
    width: auto;
    margin: 0;
    background-color: lightgray;
    padding: 10px;
    text-align: left;
}

.page-container{
  justify-content: center;
  align-items: center;
  display: grid;
  margin-top: 30px;
}
.content-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Zentriert den Inhalt vertikal */
}

.content-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
}

.puzzle-image{
  height: 300px;
  margin: 20px;
}


.puzzle-text{
  margin: 20px;
  text-align: left;
}

.text-container-0, .text-container-1, .text-container-2, .text-container-3, .text-container-4, .text-container-5{
  border-radius: 15px;
  margin: 10px;
  font-size: 20pt;
}

.text-container-0{
  background-color: rgba(217, 217, 217, 0.2);
}
.text-container-1{
  background-color: rgba(217, 217, 217, 0.2);
}
.text-container-2{
  background-color: rgba(252, 188, 88, 0.2);
}
.text-container-3{
  background-color: rgba(126, 218, 85, 0.2);
}
.text-container-4{
  background-color: rgba(55, 124, 254, 0.2);
}
.text-container-5{
  background-color: rgba(254, 50, 49, 0.2);
}

.color-text-orange{
  color: rgba(252, 188, 88, 1);
}
.color-text-blue{
  color: rgba(55, 124, 254, 1);
}
.color-text-green{
  color: rgba(126, 218, 85, 1);
}
.color-text-red{
  color: rgba(254, 50, 49, 1);
}
.color-text-grey{
  color: rgba(170, 170, 170, 1);
}

/* Media Query für Mobilgeräte */
@media (max-width: 768px) {
  .content-row{
    flex-direction: column;
  }
  .puzzle-image{
    max-width: fit-content;
    max-height: fit-content;
    margin-top: 20px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }
  .puzzle-text{
    width: 300px;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }
}

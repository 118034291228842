.inquiry-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.form-label {
  text-align: left;
}

.inquiry-form {
  display: grid;
  gap: 15px;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px; /* Setzt den Abstand zwischen den Spalten auf 5px */
}

.inquiry-form input,
.inquiry-form textarea {
  width: 100%;
  box-sizing: border-box; /* Verhindert, dass die Breite des Elements durch Padding beeinflusst wird */
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #000;
  border-radius: 4px;
}

.inquiry-form .optional {
  font-style: italic;
}

.inquiry-form button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.inquiry-form button:hover {
  background-color: #0056b3;
}

.inquiry-form p {
  margin-bottom: 15px;
}

.success-message {
  color: green;
  font-weight: bold;
  margin-bottom: 15px;
  font-size: larger;
}

.form-label.full-width {
  display: block;
  width: 100%;
}

.form-label.full-width textarea {
  width: 100%;
  resize: vertical; /* Ermöglicht das vertikale Vergrößern des Textbereichs */
  min-height: 100px; /* Setzt eine Mindesthöhe für das Textfeld */
}

.form-fieldset {
  padding: 20px;
  border-radius: 4px;
}

.form-fieldset-blue {
  border: 1px solid rgba(55, 124, 254, 1);
}

.form-fieldset-green {
  border: 1px solid rgba(126, 218, 85, 1);
}

.form-fieldset-red {
  border: 1px solid rgba(254, 50, 49, 1);
}

.form-fieldset-orange {
  border: 1px solid rgba(252, 188, 88, 1);
}

.section-title {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}

.section-title-blue {
  color: rgba(55, 124, 254, 1);
}

.section-title-green {
  color: rgba(126, 218, 85, 1);
}

.section-title-red {
  color: rgba(254, 50, 49, 1);
}

.section-title-orange {
  color: rgba(252, 188, 88, 1);
}

.subsection-title {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: left;
}

.form-fieldset label {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.form-fieldset input[type="checkbox"] {
  flex-shrink: 0; /* Verhindert, dass die Checkboxen zu viel Platz einnehmen */
  margin-right: 10px;
  width: 20px; /* Setzt die Breite der Checkboxen */
  height: 20px; /* Setzt die Höhe der Checkboxen */
}
